import { HttpClient } from 'aurelia-fetch-client';
import { DocumentCreatedResponse } from 'schema/document/document-schema';
import { IUploadFileResult, UploadSuccessFileResult, UploadErrorFileResult } from 'shared/api/upload-file-result';


export type ReportFilingApi = {
    uploadDocument(reportFilingId: number, formData: FormData): Promise<IUploadFileResult<DocumentCreatedResponse>>;
};

export const createReportFilingApi = (httpClient: HttpClient): ReportFilingApi => ({
    uploadDocument: async (reportFilingId: number, formdata: FormData) => {
        try {
            const response = await httpClient.post(`report-filings/${reportFilingId}/documents`, formdata);

            if (!response.ok) {
                throw new Error(
                    `Response status: ${response.status} - Could not upload document for Report Filing with id: ${reportFilingId}`,
                );
            }

            const data = await response.json();

            return new UploadSuccessFileResult(data);
        } catch (error) {
            console.error(error);
            
            return new UploadErrorFileResult();
        }
    },
});
